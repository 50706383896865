<template>
  <div>
    <div class="p-0 text-left mb-10" v-for="(comment, index) in commentsData" :key="index">
      <div class="flex">
        <div>
          <router-link :to="`/autorzy/${comment.author.username}`">
            <img :src="comment.author.image" alt="author image" class="w-18 h-auto">
          </router-link>
        </div>
        <div class="ml-4">
          <h3 class="font-semibold">
            {{ comment.author.name }}
          </h3>
          <p class="text-xs mt-3">
            {{ `${trans('Posted')} ${comment.createdAt}` }}
          </p>
        </div>
      </div>
      <div class="space-y-4 text-justify mt-3">
        <span v-html="comment.body"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Comments",
  props: {
    commentsData: Array,
  }
}
</script>

<template>
  <PageContainer v-if="userData">
    <PageTitle class="w-full lg:w-main-container">
      <div class="flex flex-row items-center gap-3">
        <div>
          {{ trans('PAGE_TITLE:Settings') }}
        </div>
        <VTooltip>
            <span class="flex flex-row items-center">
              <Button
                variant="link"
                @clicked="openBeforeYouUpdateSettingsModal(true)"
              >
                <base-icon  name="info" width="20" height="20">
                </base-icon>
              </Button>
            </span>
          <template #popper>
            {{ trans('LABEL:BeforeYouUpdateSettings') }}
          </template>
        </VTooltip>
      </div>
    </PageTitle>
    <PageContent>
      <div class="flex flex-col">
        <t-card
          v-if="userData && userData.bannedAt"
          class="w-full lg:w-main-container flex justify-center mb-3 lg:mb-6 border border-redAccent bg-redAccentPale"
        >
          <div class="flex flex-col">
            <div class="w-full flex flex-col lg:flex-row lg:items-center justify-between">
              <div class="flex flex-col w-full gap-3">
                <div class="flex flex-row items-center">
                  <div class="flex text-base font-semibold text-black">
                    {{ trans('LABEL:AccountBlocked') }}
                  </div>
                </div>
                <div class="text-base ban-reason lg:pl-3">
                  <div v-html="userData.banReason">
                  </div>
                  <div class="w-full flex flex-row justify-end text-s text-grayed">
                    <span v-if="!isMobile">{{ trans('LABEL:BanDate') }}&nbsp;</span>{{ userData.bannedAt }}
                  </div>
                </div>
                <hr class="w-full border-redAccent opacity-50"/>
                <span class="flex justify-end text-s">
                {{ trans('TEXT:AccountBlockedText2') }}&nbsp;<span @click="goToSpecificRoute(postCategoriesData, 'contact', {name: 'post-show'}, {banned: true})" class="underline cursor-pointer hover:opacity-70 hover:text-redAccent">{{ trans('TEXT:AccountBlockedText3') }}</span>.
              </span>
              </div>
            </div>
          </div>
        </t-card>
        <t-card class="w-full lg:w-main-container flex justify-center">
          <div class="flex flex-col">
            <div :class="{'order-1': !userData.isSubscriptionActive, 'order-3': userData.isSubscriptionActive}">
              <hr v-if="userData.isSubscriptionActive && !userData.isVerified" class="-mx-3 lg:-mx-6 my-3 lg:my-4 border-dashed border-grayed" />
              <SettingsSubscription />
              <hr class="-mx-3 lg:-mx-6 my-3 lg:my-4 border-dashed border-grayed" />
            </div>
            <div :class="{'order-2': !userData.isVerified, 'order-5': userData.isVerified}">
              <hr v-if="userData.isVerified" class="-mx-3 lg:-mx-6 my-3 lg:my-4 border-dashed border-grayed" />
              <SettingsVerification v-if="authDone" :userData="userData" />
              <hr v-if="!userData.isSubscriptionActive && !userData.isVerified" class="-mx-3 lg:-mx-6 my-3 lg:my-4 border-dashed border-grayed" />
            </div>
            <div class="order-4">
              <SettingsProfile />
            </div>

            <div class="order-6">
              <hr class="-mx-3 lg:-mx-6 my-3 lg:my-4 border-dashed border-grayed" />
              <div class="flex flex-row justify-between">
                <Button
                  variant="link"
                  class="text-s inline-flex gap-2 hover:opacity-50 cursor-pointer items-center pr-0"
                  @clicked="showDeleteAccountModal(true)"
                >
                  <base-icon name="profileXFilled">
                  </base-icon>
                  {{ trans('BUTTON:DeleteAccount') }}
                </Button>
              </div>
            </div>
          </div>
        </t-card>
      </div>
      <ValidationObserver ref="deleteAccountForm" v-slot="{ invalid }">
        <Modal
          v-if="isShowDeleteAccountModal"
          :show-modal="isShowDeleteAccountModal"
          @clicked="isShowDeleteAccountModal = false"
          @onConfirm="deleteAccountAction"
          :buttonConfirmText="trans('BUTTON:ConfirmDeleteAccount')"
          :loading="accountDeleting"
          :confirm-disabled="invalid"
          :header="trans('MODAL_TITLE:DeleteAccount')"
        >
          <template v-slot:content>
            <div class="flex flex-col gap-3 mb-4 leading-normal">
              <Hint variant="Info">
                <span v-html="transChoice('MODAL_DESCRIPTION:DeleteAccount', configData.deleteAccountDays)"></span>
              </Hint>
              <FieldWrapper>
                <TextField
                  isPassword
                  field="password"
                  rules="required"
                  v-model="passwordToConfirm"
                  :placeholder="trans('LABEL:ConfirmPasswordToDeleteAccount')"
                />
              </FieldWrapper>
            </div>
          </template>
        </Modal>
      </ValidationObserver>
    </PageContent>
    <Modal
      hide-footer
      variant="post"
      v-if="isShowBeforeYouUpdateSettingsModal"
      :show-modal="isShowBeforeYouUpdateSettingsModal"
      @clicked="openBeforeYouUpdateSettingsModal(false)"
    >
      <template #content>
        <PostShow slug="before-you-update-settings" />
      </template>
    </Modal>
  </PageContainer>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import UserCard from "@/views/content/components/UserCard";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import SettingsVerification from "@/views/content/settings/components/SettingsVerification";
import SettingsProfile from "@/views/content/settings/components/SettingsProfile";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SettingsSubscription from "@/views/content/settings/components/SettingsSubscription";
import Modal from "@/utils/modal/Modal";
import TextField from "@/utils/crud/components/field-types/Text.vue";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper.vue";
import {goToSpecificRoute} from "@/common/helpers/utils";
import Hint from "@/views/content/components/Hint"
import PageContent from "@/layouts/components/pages/PageContent";
import PostShow from "@/views/content/posts/PostShow.vue";
import api from "@/common/services/api";

export default {
  name: "FavouritesView",
  components: {
    PageContent,
	  FieldWrapper,
	  TextField,
    Modal,
    SettingsSubscription, PageTitle, PageContainer,
    SettingsProfile, SettingsVerification, UserCard,
    Button, BaseIcon, Hint, PostShow
  },
  data() {
    return {
      goToSpecificRoute: goToSpecificRoute,
      isShowDeleteAccountModal: false,
	    passwordToConfirm: null,
      isShowBeforeYouUpdateSettingsModal: false
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters('account', ['userData', 'accountDeleted', 'accountDeleting', 'authDone']),
    ...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('lang', ['configData']),
    isMobile() {
      return checkIsMobileDevice();
    },
  },
  methods: {
    ...mapActions('account', ['deleteAccountRequest']),
	  ...mapMutations('account', ['setUserSettingsModalViewed']),

    deleteAccountAction(){
      this.deleteAccountRequest({password: this.passwordToConfirm});
    },

    showDeleteAccountModal(value) {
      this.isShowDeleteAccountModal = value;
    },

    openBeforeYouUpdateSettingsModal(value) {
			if (value === false && ! this.userData.settingsModalViewed) {
				this.sendSettingsViewedRequest();
			}
      this.isShowBeforeYouUpdateSettingsModal = value;
    },

	  sendSettingsViewedRequest() {
		  api.post(`/settings-modal-viewed`)
				  .then(
						  success => {
								this.setUserSettingsModalViewed()
						  },
						  error => {
						  }
				  )
	  }
  },
	beforeMount() {
	},
	mounted() {
		this.isShowBeforeYouUpdateSettingsModal = !this.userData.settingsModalViewed;
  }
}
</script>


<template>
  <div>
    <t-textarea rows="5" :placeholder="trans('Write something here...')" />
    <div class="flex justify-end mt-3">
      <Button :text="trans('Post now')" />
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";


export default {
  name: "CommentForm",
  components: {Button}

}
</script>

<template>
  <div class="flex text-black rounded border "
    :class="[
      large?'text-base px-3 py-1.5 gap-1':'text-s px-2 py-1',
      'border-badge'+variant,
      'bg-badge'+variant+'Pale'
    ]"
  >
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "Hint",
  props: {
    large: {
      type: Boolean,
    },
    variant: {
      type:String,
    }
  }
}

</script>
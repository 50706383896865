<template>
  <div v-if="userDataEdit" class="flex flex-col w-full gap-3 lg:pl-3">
    <div class="flex flex-row items-center w-full">
      <TextField
        class="w-full"
        text-attr="textAttr"
        v-model="userDataEdit.logoutWww"
        :placeholder="trans('LABEL:LogoutWwwDefaultComment')"
      />
    </div>
    <div class="flex flex-row justify-start">
      <Button
        :class = "{'border-black': btnDisabled}"
        variant="buttonRedOutlined"
        :text="trans('BUTTON:LogoutWwwDefaultAction')"
        :disabled="btnDisabled"
        @clicked="btnDisabled"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TextField from "@/utils/crud/components/field-types/Text";
export default {
  name: "LogoutWwwForm",
  components: {TextField, Button},
  props: {
    userData: Object,
  },
  data() {
    return {
      userDataEdit: null,
      loadings: {
        btnDefaultLogout: false,
        btnLogoutWww: false,
      }
    }
  },
  watch: {
    sendEditStatus(val) {
      if(val) {
        this.setEditRequestStatus(false);
        this.updateLogoutWwwAction()
      }
    }
  },
  computed: {
    ...mapGetters('settings', ['loadingUpdateProfile', 'sendEditStatus']),
	  btnDisabled() {
			if(this.userDataEdit) {
				return this.userDataEdit.logoutWww === this.trans('LABEL:LogoutWwwDefaultComment')
			}
			return true;
	  }
  },
  methods: {
    ...mapActions('settings', ['updateProfileSettingsRequest']),
    ...mapMutations('settings', ['setEditRequestStatus']),
    updateLogoutWwwAction(logoutWww = null) {
      let payload = {};
      payload.logout_www = this.userDataEdit.logoutWww;

      if(logoutWww !== null) {
        payload.logout_www = logoutWww
      }

      this.updateProfileSettingsRequest(payload);
    },
  },
  mounted() {
    this.userDataEdit = structuredClone(this.userData)
  }
}
</script>

<template>
  <div class="w-full flex flex-col lg:flex-row lg:justify-center">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageContent"
}
</script>

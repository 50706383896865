<template>
  <div class="flex flex-col">
    <div class="flex flex-col lg:flex-row justify-between">
      <div class="flex flex-row items-center space-x-4">
        <div class="text-base font-semibold text-black py-1">
          {{ trans('LABEL:Subscription') }}
        </div>
        <BadgeFrame>
          <Badge v-if="currentSubscription" class="w-min whitespace-nowrap" tagName="span" :variant="currentSubscription.message.color">
            {{ trans(currentSubscription.message.text) }}
          </Badge>
        </BadgeFrame>
      </div>
    </div>
    <div v-if="!currentSubscription" class="w-full text-center">
      <Loader small no-border/>
    </div>
    <div v-else class="flex flex-col lg:flex-row lg:justify-end gap-3 lg:gap-6 lg:items-center mt-3 lg:mt-0">
      <div class="text-s text-right">
        <Button
          v-if="currentSubscription"
          variant="routerLink"
          class="px-0"
          :to="{name: 'subscription-details'}"
        >
          {{ trans('BUTTON:SubscriptionHistory') }}
        </Button>
      </div>
      <div class="flex justify-end">
        <Button
          :variant="{'buttonRedOutlined':userData.isSubscriptionActive, 'buttonRed':!userData.isSubscriptionActive}"
          :to="{name: 'subscription'}"
          :text="trans(userData.isSubscriptionActive ? 'BUTTON:SubscriptionExtend' : 'BUTTON:SubscriptionBuy')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/views/Loader";

export default {
  name: "SettingsSubscription",
  components: {Badge, BadgeFrame, Button, Loader},
	watch: {
		langLocale() {
			this.fetchSubscriptionStatusInfo();
		}
	},
	computed: {
		...mapGetters('account', ['userData']),
		...mapGetters('lang', ['langLocale']),
		...mapGetters('subscriptions', ['currentSubscription'])
	},
	methods: {
		...mapActions('subscriptions', ['fetchSubscriptionStatusInfo'])
	},

}
</script>


<template>
  <div>
    <!-- Visibility -->
    <div class="w-full flex flex-col lg:flex-row lg:items-center justify-between">
      <div class="flex flex-col w-full">
        <div class="flex flex-row items-center space-x-4">
          <div class="flex text-base font-semibold text-black py-1">
            {{ trans('LABEL:ProfileVisibility') }}
          </div>
          <div>
            <BadgeFrame v-if="userData.isHidden">
              <Badge tagName="span" :variant="'badgeInfo'">
                {{ trans('LABEL:ProfileHiddenBadge') }}
              </Badge>
            </BadgeFrame>
          </div>
        </div>
        <div class="mt-2 text-right flex flex-row justify-end">
          <div class="flex flex-col lg:flex-row justify-evenly lg:justify-end w-full lg:space-x-6 pl-3 items-start lg:items-center">
            <label v-for="(index, type) in langData.profileVisibilityTypes" :key="index"
              class="flex items-center cursor-pointer hover:text-redAccentLight hover:opacity-70"
            >
              <t-radio v-model="form.profileVisibility" name="profile_visibility" :value="type" />
              <span class="ml-2 text-base">{{ langData.profileVisibilityTypes[type] }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-3 lg:my-4 border-grayedHR" />
    <!-- After login screen -->
    <div class="flex flex-col lg:flex-col justify-between">
      <div class="flex flex-row justify-start text-base font-semibold text-black py-1">
        {{ trans('LABEL:AfterLoginScreen') }}
      </div>
      <div class="flex flex-col lg:flex-row justify-evenly lg:justify-end w-full mt-2 lg:space-x-6 pl-3 items-start lg:items-center">
        <label class="flex items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
          <t-radio v-model="form.loginScreen" name="login_screen" value="profile" />
          <span class="ml-2 text-base">{{ trans('LABEL:myProfile') }}</span>
        </label>
        <label class="flex items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
          <t-radio v-model="form.loginScreen" name="login_screen" value="chats" />
          <span class="ml-2 text-base">{{ trans('LABEL:messages') }}</span>
        </label>
        <label class="flex items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
          <t-radio v-model="form.loginScreen" name="login_screen" value="search" />
          <span class="ml-2 text-base">{{ trans('LABEL:search') }}</span>
        </label>
        <label class="flex items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
          <t-radio v-model="form.loginScreen" name="login_screen" :value="`posts/news`" />
          <span class="ml-2 text-base">{{ trans('LABEL:news') }}</span>
        </label>
      </div>
    </div>
    <hr class="my-3 lg:my-4 border-grayedHR" />
    <!-- After logout WWW -->
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-start text-base font-semibold text-black py-1">
          {{ trans('LABEL:AfterLogoutWww') }}
      </div>
      <div class="flex flex-col lg:flex-row gap-2 lg:gap-6 items-end lg:justify-end ">
        <FakeTextField
          class="w-full justify-start lg:justify-end"
          :text="userData.logoutWww ? userData.logoutWww : trans('LABEL:LogoutWwwDefaultComment')"
        />
        <Button
          variant="buttonRedOutlined"
          :text="trans('BUTTON:Change')"
          @clicked="openLogoutWwwModal(true)"
        />
      </div>
    </div>
    <hr class="my-3 lg:my-4 border-grayedHR" />

	  <!-- Lang select -->
	  <div class="flex flex-col lg:flex-col justify-between">
		  <div class="flex flex-row justify-start text-base font-semibold text-black py-1">
			  {{ trans('LABEL:ApplicationLanguage') }}
		  </div>
		  <div class="flex flex-col lg:flex-row justify-evenly lg:justify-end w-full mt-2 lg:space-x-6 space-y-3 lg:space-y-0 pl-3 items-start lg:items-center">
			  <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				  <t-radio v-model="form.appLang" name="app_lang" value="pl" />
				  <img :src="require(`@/assets/images/flags/pl.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
          <div>{{trans("LANGUAGE:pl")}}</div>
			  </label>
        <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				  <t-radio v-model="form.appLang" name="app_lang" value="en" />
				  <img :src="require(`@/assets/images/flags/en.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
          <div>{{trans("LANGUAGE:en")}}</div>
			  </label>
        <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				  <t-radio v-model="form.appLang" name="app_lang" value="uk" />
				  <img :src="require(`@/assets/images/flags/uk.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
          <div>{{trans("LANGUAGE:uk")}}</div>
			  </label>
		  </div>
	  </div>
	  <hr class="my-3 lg:my-4 border-grayedHR" />

    <!-- Email -->
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-start text-base font-semibold text-black py-1">
        {{ trans('LABEL:Email') }}
      </div>
      <div class="flex flex-col lg:flex-row gap-2 lg:gap-6 items-end lg:justify-end ">
        <FakeTextField
          class="w-full justify-start lg:justify-end"
          :text="userData.email"
        />
        <Button
          variant="buttonRedOutlined"
          :text="trans('BUTTON:Change')"
          @clicked="openEmailModal(true)"
        />
      </div>
    </div>
    <hr class="my-3 lg:my-4 border-grayedHR" />
    <!-- Password -->
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-start text-base font-semibold text-black py-1">
        {{ trans('LABEL:PasswordUpdate') }}
      </div>
      <div class="flex flex-col lg:flex-row gap-0 lg:gap-6 items-end lg:justify-end ">
        <FakeTextField
          class="w-full justify-start lg:justify-end"
          :text="'****************'"
        />
        <Button
          variant="buttonRedOutlined"
          :text="trans('BUTTON:Change')"
          @clicked="openPasswordModal(true)"
        />
      </div>
    </div>

    <!--  LOGOUT WWW MODAL  -->
    <Modal
      v-if="dataEditModals.isShowLogoutWwwForm"
      :show-modal="dataEditModals.isShowLogoutWwwForm"
      @clicked="openLogoutWwwModal(false)"
      @onConfirm="editRequest"
      :loading="loadingUpdateProfile"
      :header="trans('MODAL_TITLE:LogoutWww')"
    >
      <template v-slot:content>
        <LogoutWwwForm :userData="userData" />
      </template>
    </Modal>

    <!--  EMAIL FORM MODAL  -->
    <ValidationObserver ref="emailForm" v-slot="{ invalid }">
      <Modal
        v-if="dataEditModals.isShowEmailForm"
        :show-modal="dataEditModals.isShowEmailForm"
        @clicked="openEmailModal(false)"
        @onConfirm="editRequest"
        :loading="loadingUpdateProfile"
        :confirm-disabled="invalid"
        :header="trans('MODAL_TITLE:Email')"
      >
        <template v-slot:content>
          <EmailForm :userData="userData" />
        </template>
      </Modal>
    </ValidationObserver>


    <!--  PASSWORD FORM MODAL  -->
    <ValidationObserver ref="passwordForm" v-slot="{ invalid }">
      <Modal
        v-if="dataEditModals.isShowPasswordForm"
        :show-modal="dataEditModals.isShowPasswordForm"
        @clicked="openPasswordModal(false)"
        @onConfirm="editRequest"
        :loading="loadingUpdateProfile"
        :confirm-disabled="invalid"
        :header="trans('MODAL_TITLE:PasswordUpdate')"
      >
        <template v-slot:content>
          <PasswordForm :userData="userData" />
        </template>
      </Modal>
    </ValidationObserver>

  </div>

</template>

<script>
import Badge from "@/utils/crud/components/Badge";
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapGetters, mapActions, mapMutations} from "vuex"
import LogoutWwwForm from "@/views/content/settings/components/forms/LogoutWwwForm";
import FakeTextField from "@/utils/crud/components/field-types/FakeTextField";
import Modal from "@/utils/modal/Modal";
import EmailForm from "@/views/content/settings/components/forms/EmailForm";
import PasswordForm from "@/views/content/settings/components/forms/PasswordForm";
import BaseIcon from "@/utils/icons/BaseIcon";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";

const initialLoadings = () => {
  return {
    btnVisibility: false,
    btnLoginScreen: false,
    btnLogoutWww: false,
    btnEmail: false,
    btnPassword: false,
    btnDefaultLogout: false,
  }
}

export default {
  name: "SettingsProfile",
  components: {
    BadgeFrame,
    Badge, BaseIcon, PasswordForm, EmailForm, Modal, FakeTextField, LogoutWwwForm, TextField, Button, FieldWrapper},
  data() {
    return {
      form: {
        loginScreen: null,
        email: null,
        emailConfirmation: null,
        passwordCurrent: null,
        passwordNew: null,
        passwordConfirmation: null,
        profileVisibility: null,
	      appLang: null,
      },
      profileLoaded: false,
      watchData: false,
      loadings: initialLoadings(),
      dataEditModals: {
        isShowLogoutWwwForm: false,
        isShowEmailForm: false,
        isShowPasswordForm: false,
      },
    }
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('settings', ['profileUpdated', 'loadingUpdateProfile']),
    ...mapGetters('lang', ['langData'])

  },
  watch: {
    userData() {
      this.form.logoutWww = null;
      this.form.email = null;
      this.form.emailConfirmation = null;
      this.form.passwordCurrent = null;
      this.form.passwordNew = null;
      this.form.passwordConfirmation = null;
    },
    loadingUpdateProfile(val) {
      if (!val) {
        this.loadings = initialLoadings()
      }
    },
    'form.loginScreen'() {
      if(this.watchData) {
        this.updateLoginScreenAction();
      }
    },
	  'form.appLang'() {
		  if(this.watchData) {
			  this.updateAppLangAction();
		  }
	  },
    'form.profileVisibility'() {
      if(this.watchData) {
        this.updateProfileVisibilityAction();
      }
    },
    profileLoaded(val) {
      if(val) {
        this.watchData = true;
      }
    },
    profileUpdated(val) {
      if(val) {
				this.fetchDashboardData();
        Object.keys(this.dataEditModals).forEach((key) => {
          if(this.dataEditModals[key] === true) {
            this.$set(this.dataEditModals, key, false);
          }
        });
      }
    },
  },
  methods: {
    ...mapActions('settings', ['updateProfileSettingsRequest']),
    ...mapMutations('settings', ['setEditRequestStatus']),
	  ...mapActions('dashboard', ['fetchDashboardData']),

    updateProfileVisibilityAction() {
      let payload = {
        profile_visibility: this.form.profileVisibility,
      };

      this.updateProfileSettingsRequest(payload);
    },
    updateLoginScreenAction() {
      let payload = {};
      payload.login_screen = this.form.loginScreen;

      this.updateProfileSettingsRequest(payload);
    },

	  updateAppLangAction() {
		  let payload = {};
		  payload.lang = this.form.appLang;

		  this.updateProfileSettingsRequest(payload);
	  },

    setFormData() {
      this.form.loginScreen = this.userData.loginScreen;
      this.form.profileVisibility = this.userData.profileVisibility.visibility;
      this.form.appLang = this.userData.lang;
      this.setProfileLoaded();
    },

    setProfileLoaded() {
      this.profileLoaded = true;
    },

    openLogoutWwwModal(val) {
      this.dataEditModals.isShowLogoutWwwForm = val;
    },

    openEmailModal(val) {
      this.dataEditModals.isShowEmailForm = val;
    },

    openPasswordModal(val) {
      this.dataEditModals.isShowPasswordForm = val;
    },

    editRequest() {
      this.setEditRequestStatus(true);
    },
  },
  mounted() {
    this.setFormData();
  }
}
</script>


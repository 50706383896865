<template>
  <div class="relative group cursor-pointer" v-if="postData"
    :class="[getHeightOfScreenWidth(0.75),
    {
      'lg:h-first-post-img lg:max-h-first-post-img': !small,
      'lg:h-post-card-img lg:max-h-post-card-img':small,
    }]"
       @click="$emit('post-clicked', postData.slug)"
       :style="[{background: 'url(' + postData.image + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]">
    <div v-if="!emptyPost" class="flex flex-col justify-end h-full leading-tight">
      <div class="w-full flex flex-col px-3 lg:px-6 bg-white bg-opacity-95"
        :class="small?'pt-3 pb-2':'py-4'"
      >
        <div :class="small?'space-y-2':'space-y-2'">
          <div class="text-black font-semibold truncate"
            :class="small?'text-base':'text-2xl'"
          >
            {{postData.title}}
          </div>
          <div v-if="!small" class="text-grayedDark text-lg">
            <span v-html="postData.excerpt"/>
          </div>
        </div>
        <hr v-if="!emptyPost" class="border-grayed border-dashed -mx-3 lg:-mx-6" :class="small?'my-2':'my-3'"/>
        <div v-if="!emptyPost"
          class="flex flex-row text-grayed justify-end"
          :class="small?'text-xs':'text-sm'"
        >
          <span v-if="!small" class="text-black hidden lg:block">{{ postData.authorName }}</span>
          <span v-if="!small" class="hidden lg:block">&nbsp;|&nbsp;</span><span class="text-redAccent"
                :class="{'hover:opacity-50': !isCurrentRoute(postData.postCategorySlug)}"
                @click.stop="!isCurrentRoute(postData.postCategorySlug) ? goToCategory(postData.postCategorySlug) : null">
            {{ trans(`POST_CATEGORY:${postData.postCategorySlug}`) }}
          </span>
            &nbsp;|&nbsp;<span class="text-black">{{ postData.publishedAt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {excerpt} from "@/common/helpers/utils";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";

export default {
  name: "PostCard",
  props: {
    postData: Object,
    small: Boolean,
	  emptyPost: Boolean,
  },
  data() {
    return {
      excerpt:excerpt,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
    }
  },
	computed: {
		// nie wiem do czego to ma służyć więc zostawiam czyste computed żeby błedu nie waliło, można tu dodać jakieś klasy
		transformScale() {
			return '';
		}
	},
  methods: {
    goToCategory(slug) {
      router.push({name: 'posts', params: {postCategory: slug}});
    },
    isCurrentRoute(slug) {
      return this.$route.params.postCategory === slug;
    },
  }
}
</script>

<style scoped>
  .bg-first-post {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 54%);
  }
</style>

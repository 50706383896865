<template>
  <div class="flex flex-col lg:pl-3 gap-3">
    <div>
      {{ userData.email }}
    </div>
    <TextField class="w-full" v-model="form.email" field="email" rules="required|email|max:255" :placeholder="trans('LABEL:EmailNew')" tabindex="1" />
    <TextField class="w-full" v-model="form.emailConfirmation" field="email_confirmation" rules="required|confirmed:email" tabindex="2" :placeholder="trans('LABEL:EmailNewRepeat')" />
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TextField from "@/utils/crud/components/field-types/Text";
export default {
  name: "EmailForm",
  components: {TextField, Button},
  props: {
    userData: Object,
  },
  data() {
    return {
      form: {},
    }
  },
  watch: {
    sendEditStatus(val) {
      if(val) {
        this.setEditRequestStatus(false);
        this.updateEmailAction()
      }
    }
  },
  computed: {
    ...mapGetters('settings', ['loadingUpdateProfile', 'sendEditStatus'])
  },
  methods: {
    ...mapActions('settings', ['updateProfileSettingsRequest']),
    ...mapMutations('settings', ['setEditRequestStatus']),
    updateEmailAction() {
      let payload = {};
      payload.email = this.form.email;
      payload.email_confirmation = this.form.emailConfirmation;

      this.updateProfileSettingsRequest(payload);
    },
  },
  mounted() {
  }
}
</script>

<template>
  <div class="flex flex-col lg:flex-row gap-x-2 lg:gap-x-3 gap-y-2 flex-wrap lg:my-1">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: "BadgeFrame",
  props: {
    tagName: {
      type: String,
    },
    variant: {
      type: String,
    },
  }
}
</script>
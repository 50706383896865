<template>
  <div class="flex flex-col">
    <div class="flex flex-col lg:flex-row justify-between">
      <div class="flex flex-row items-center space-x-4">
        <div class="text-base font-semibold text-black  leading-normal py-1">
          {{ trans('LABEL:Verification') }}
        </div>
        <BadgeFrame v-if="verificationData">
          <Badge v-if="userData.isVerified" tagName="span" variant="badgeSuccess">
            {{ trans('LABEL:VerificationIsCompletedBadge') }}
          </Badge>
          <Badge v-else tagName="span" variant="badgeError">
            {{ trans('LABEL:VerificationIsNotCompletedBadge') }}
          </Badge>
        </BadgeFrame>
      </div>
    </div>
    <div v-if="verificationData === null" class="w-full text-center">
      <Loader small no-border/>
    </div>
    <div class="flex flex-col lg:flex-row lg:justify-end lg:items-center lg:gap-6">
      <div v-if="verificationData !== null && verificationData.updatedAt !== null" class="text-s text-right hidden lg:block" >
        <Button
          variant="routerLink"
          class="px-0"
          :to="{name: 'verification-details'}"
        >
          {{ trans('BUTTON:VerificationDetails') }}
        </Button>
      </div>
      <div v-if="verificationData !== null && !verificationData.verified" class="flex justify-end" >
        <template v-if="userData.isSubscriptionActive">
          <Button
            variant="buttonRed"
            class="mt-3 lg:mt-0"
            :to="{name: 'verification'}"
            :disabled="verificationData.verified || verificationData.init || verificationData.limit.limitReached"
            :text="trans('BUTTON:VerificationShow')"
          />
        </template>
        <template v-else>
          <Button
            variant="buttonRed"
            class="mt-3 lg:mt-0"
            @clicked="openShowNoSubscriptionModal(true)"
            :text="trans('BUTTON:VerificationShow')"
          />
        </template>
      </div>
      <div v-if="verificationData !== null && verificationData.updatedAt !== null" class="text-s text-right lg:hidden mt-3 lg:mt-0" >
        <Button
          variant="routerLink"
          class="px-0"
          :to="{name: 'verification-details'}"
        >
          {{ trans('BUTTON:VerificationDetails') }}
        </Button>
      </div>
    </div>
    <Modal
      :showModal="isShowNoSubscriptionModal"
      :buttonConfirmText="trans('BUTTON:SubscriptionBuy')"
      @onConfirm="goToSubscriptions"
      @clicked="openShowNoSubscriptionModal(false)"
      :header="trans('MODAL_TITLE:Attention')"
    >
      <template v-slot:content>
        {{ trans('MODAL_DESCRIPTION:BuySubscriptionToVerify') }}
      </template>
    </Modal>
  </div>
</template>

<script>
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import {mapActions, mapGetters, mapMutations} from "vuex";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import router from "@/router";
export default {
  name: "SettingsVerification",
  components: {Badge, BadgeFrame, Button, Loader, Modal},
  data() {
    return {
      isShowNoSubscriptionModal: false,
    }
  },
  props: {
    userData: Object,
  },
  computed: {
    ...mapGetters('verification', ['verificationData', 'currentVerification']),
    isMobile() {
      return checkIsMobileDevice();
    }
  },
	watch: {
		currentVerification: {
			immediate: true,
			handler(val) {
				if(val) {
					if(this.currentVerification.init) {
						this.fetchVerificationData();
					} else {
						this.setVerificationData(this.currentVerification)
					}
				}
			}

		}
	},
  methods: {
    ...mapActions('verification', ['fetchVerificationData']),
    ...mapMutations('verification', ['setVerificationData']),
    openShowNoSubscriptionModal(value) {
      this.isShowNoSubscriptionModal = value;
    },
    goToSubscriptions() {
      router.push({name: 'subscription'})
    }
  },
  mounted() {

  }
}
</script>

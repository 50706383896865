<template>
  <div class="w-full flex flex-col lg:pl-3 gap-3">
    <Hint variant="Info">
      {{ trans('LABEL:PasswordUpdateComment') }}
    </Hint>
    <div class="w-full flex flex-col lg:flex-row items-top justify-between">
      <form class="w-full flex flex-col gap-3">
        <TextField v-model="form.passwordCurrent"
          class="w-full"
          :placeholder="trans('LABEL:PasswordUpdatePlaceholderCurrent')"
          field="password_current"
          rules="required"
          isPassword
        />
        <TextField v-model="form.passwordNew"
          class="w-full"
          :placeholder="trans('LABEL:PasswordUpdatePlaceholder')"
          field="password"
          autocomplete
          rules="required|min:8|max:50"
          isPassword
        />
        <TextField v-model="form.passwordConfirmation"
          class="w-full"
          :placeholder="trans('LABEL:PasswordUpdatePlaceholderRepeat')"
          field="password_confirmation"
          rules="required|confirmed:password"
          isPassword
        />
      </form>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import Hint from "@/views/content/components/Hint"
import {mapActions, mapGetters, mapMutations} from "vuex";
import TextField from "@/utils/crud/components/field-types/Text";
export default {
  name: "PasswordForm",
  components: {TextField, Button, Hint},
  props: {
    userData: Object,
  },
  data() {
    return {
      form: {},
    }
  },
  watch: {
    sendEditStatus(val) {
      if(val) {
        this.setEditRequestStatus(false);
        this.updatePasswordAction()
      }
    }
  },
  computed: {
    ...mapGetters('settings', ['loadingUpdateProfile', 'sendEditStatus'])
  },
  methods: {
    ...mapActions('settings', ['updateProfileSettingsRequest']),
    ...mapMutations('settings', ['setEditRequestStatus']),

    updatePasswordAction() {
      let payload = {};
      payload.password_current = this.form.passwordCurrent;
      payload.password = this.form.passwordNew;
      payload.password_confirmation = this.form.passwordConfirmation;

      this.updateProfileSettingsRequest(payload);
    },
  },
  mounted() {
  }
}
</script>
